import React, { useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Tabs, TabsProps } from 'antd';
import Trader from '@app/components/tables/Traders/Trader';
import PnLReport from '@app/components/tables/Traders/PnLReport';
interface FieldData {
  name: string | number;
  //  
  value?: any;
}
const TradersPage: React.FC = () => {
  const [activeKey, setActiveKey] = useState('1');
  const onChange = (key:string) => {
    setActiveKey(key);
  };
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Traders',
      children: <Trader />
    },
    {
      key: '2',
      label: 'Reports',
      children: <PnLReport />,
    }
  ];
  return (
    <>
      <PageTitle>Traders/Reports</PageTitle>
      <Tabs activeKey={activeKey} onChange={onChange} items={items} destroyInactiveTabPane>          
      </Tabs>
    </>
  );
};

export default TradersPage;
