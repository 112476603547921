import React, { useEffect, useState } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { deleteTrader, getTraderData, TraderDto, upsertTrader } from '@app/api/table.api';
import { ColumnsType } from 'antd/lib/table';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useMounted } from '@app/hooks/useMounted';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { notificationController } from '@app/controllers/notificationController';
import { PlusCircleFilled } from '@ant-design/icons';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';

const initialFormValues: TraderDto = {
  id: 0,
  username: undefined,
  fullName: undefined,
  userIp: undefined
};

const Trader: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [traderData, setTraderData] = useState<TraderDto[]>([]);
  const [isShowUpsertModal, setShowUpsertModal] = useState<boolean>(false);
  const [isEditConfig, setIsEditConfig] = useState<boolean>(false);

  const { isMounted } = useMounted();

  const [form] = BaseForm.useForm();

  const fetchData = () => {
    setLoading(true);
    getTraderData().then((res) => {
      if (isMounted.current) {
        setTraderData(res);  
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, [isMounted]); 
  
  const handleDeleteTrader = (id: number) => {
      
      deleteTrader(id).then((rs) => {
        if(!rs)
        {
          notificationController.error({ message: 'Something went wrong! Cannot delete this trader' });
        }
        else{
          fetchData();
        }
      }).catch(()=>{
        notificationController.error({ message: 'Something went wrong! Cannot delete this trader' });
      })
  }

  const showUpsertModal = (rowId?: number) => {
    if(rowId)
    {
      //Edit 
      setIsEditConfig(true);
      let cloneData = [ ...traderData ];
      cloneData.forEach(function (part, index, theArray) {
        if (theArray[index].id === rowId) {
          const username = theArray[index].username;
          const fullName = theArray[index].fullName;
          const userIp = theArray[index].userIp;
          const id = theArray[index].id;

          form.setFieldsValue({
            ...initialFormValues,
            username: username,
            id: id,
            fullName: fullName,
            userIp: userIp
          });
        }
      });
    }
    else{
      //Add new
      setIsEditConfig(false);
      form.setFieldsValue({
        ...initialFormValues
      });
    }
    setShowUpsertModal(true);

  } 

  const handleUpsertTrader = () => {
    const id = form.getFieldValue('id');
    const username = form.getFieldValue('username');
    const fullName = form.getFieldValue('fullName');
    const userIp = form.getFieldValue('userIp');

    const formValues: TraderDto = {
      id: id,
      username: username,
      fullName: fullName,
      userIp: userIp
    };
    upsertTrader(formValues).then((rs) => {
      if(!rs)
      {
        notificationController.error({ message: 'Something went wrong!' });
      }
      else{
        fetchData();
      }
    }).catch(()=>{
      notificationController.error({ message: 'Something went wrong!' });
    }).finally(()=> {
      setShowUpsertModal(false);
    })
  };
  const columns: ColumnsType<TraderDto> = [
    {
      title: () => (<div>Full Name</div>),
      dataIndex: 'fullName',
    },
    {
      title: () => (<div>Username</div>),
      dataIndex: 'username',
      width: '120px'
    },
    {
      title: () => (<div>Ip Address</div>),
      dataIndex: 'userIp',
      width: '120px'
    },
    {
      title: () => (<div></div>),
      dataIndex: 'delete',
      width: '200px',
      render: (text: string, record: { id?: number }) => {
        return (
          <BaseRow justify='center'>
            <BaseCol>
              <BaseButton size='small' type='primary' onClick={() => showUpsertModal(record.id)}> Edit </BaseButton>
            </BaseCol>
            <BaseCol>
              <BaseButton size='small' type='dashed' style={{marginLeft: "10px"}} onClick={() => handleDeleteTrader(record.id ?? 0)}> Delete </BaseButton>
            </BaseCol>
          </BaseRow>
        );
      }
    }
  ];
  return (
    <>
      <BaseRow align="middle" justify='end' >
        <BaseCol>
          <BaseButton type='primary' icon={(<PlusCircleFilled />)} onClick={() => showUpsertModal()}> Add </BaseButton>
        </BaseCol>
      </BaseRow>
      <BaseTable
        loading={loading}
        columns={columns}
        dataSource={traderData}
        rowKey="id"
        showHeader={true}
        pagination={false}
      />
      {isShowUpsertModal && (
        <BaseModal
          title={isEditConfig ? 'Edit Trader' : 'Add Trader'}
          centered
          open={isShowUpsertModal}
          size="medium"
          style={{ fontSize: '12px' }}
          footer={
            <BaseButton type="primary" size='middle' style={{ width: '100%' }} onClick={() => form.submit()}>
              Save
            </BaseButton>}
          onCancel={() => setShowUpsertModal(false)}
        >
          <BaseButtonsForm
            name="upsertForm"
            form={form}
            isFieldsChanged={true}
            initialValues={initialFormValues}
            footer={<></>}
            onFinish={handleUpsertTrader}
          >
            <BaseRow gutter={{ xs: 5, md: 5, xl: 10 }}>
              <BaseCol xs={24} md={24}>
                <BaseButtonsForm.Item name='username' label='Username'>
                  <BaseInput />
                </BaseButtonsForm.Item>
              </BaseCol>
            </BaseRow>
            <BaseRow gutter={{ xs: 5, md: 5, xl: 10 }}>
              <BaseCol xs={24} md={24}>
                <BaseButtonsForm.Item name='fullName' label='Full Name'>
                  <BaseInput />
                </BaseButtonsForm.Item>
              </BaseCol>
            </BaseRow>
            <BaseRow gutter={{ xs: 5, md: 5, xl: 10 }}>
              <BaseCol xs={24} md={24}>
                <BaseButtonsForm.Item name='userIp' label='IP Address'>
                  <BaseInput />
                </BaseButtonsForm.Item>
              </BaseCol>
            </BaseRow>
          </BaseButtonsForm>
        </BaseModal>
      )}
    </>
  );
};

export default Trader;
