import React, { useEffect, useState } from 'react';
import { Tag } from 'antd';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import formatNumber, { cancelAllOrder, cancelOrder, OpenOrderTableRow } from '@app/api/table.api';
import { ColumnsType } from 'antd/lib/table';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { notificationController } from '@app/controllers/notificationController';

interface OpenOrdersProps {
  tableData: OpenOrderTableRow[];
  setTableData: (tableData: OpenOrderTableRow[]) => void;
}

const OpenOrders: React.FC<OpenOrdersProps> = ({ tableData, setTableData }) => {
   
  const handleCancelOrder = (symbol: string, cid: string) => {    
    cancelOrder(symbol, cid).then((res) => {
      if (!res) {
        notificationController.error({ message: "something went wrong!" });
      }
      else{
        setTableData(tableData.filter((item) => item.clientOrderId !== cid));
      }
    }).catch((err) => {
      notificationController.error({ message: err.message });
    });

  };

  const handleCancelAllOrder = () => {    
    cancelAllOrder().then((res) => {
      if (!res) {
        notificationController.error({ message: "something went wrong!" });
      }
      else{
        setTableData([]);
      }
    }).catch((err) => {
      notificationController.error({ message: err.message });
    });

  };

  const columns: ColumnsType<OpenOrderTableRow> = [
    {
      title: () => (<div></div>),
      dataIndex: 'instrumentId',
      width: '30%',
      render: (text: string, record: { instrumentId: string; instrumentType: string; orderSide: string; orderType: string; tradeMode: string; leverage?: number }) => {
        return (
          <div>
            <BaseRow style={{padding: '5px 0px'}}>
              <BaseCol>
                <span style={{fontSize: '16px'}}>{text}</span>
              </BaseCol>
              <BaseCol style={{padding: '0px 5px'}}>
                <i style={{fontSize: '12px'}}>{record.instrumentType}</i>
              </BaseCol>
            </BaseRow>
            <BaseRow style={{padding: '5px 0px'}}>
              <BaseCol>
                <Tag color='gray'>{record.tradeMode}</Tag>
              </BaseCol>
              <BaseCol>
                <Tag color={record.orderSide === 'Buy' ? 'green' : 'red'}>{record.orderSide} {record.leverage ? record.leverage + 'x' : ''}</Tag>
              </BaseCol>
            </BaseRow>
          </div>
        );
      }
    },
    {
      title: () => (<div></div>),
      dataIndex: 'price',
      width: '15%',
      render: (text: string, record: { price: number; amount: number }) => {
        return (
          <div>
            <BaseRow style={{padding: '5px 0px'}}>
              <BaseCol>
                <span>Price:</span><br/>
                <span>{text}</span>
              </BaseCol>              
            </BaseRow>
            <BaseRow style={{padding: '5px 0px'}}>              
              <BaseCol>
                <span>Amount:</span><br/>
                <span>${formatNumber(record.amount, 2)}</span>
              </BaseCol>
            </BaseRow>
          </div>
        );
      }
    },
    {
      title: () => (<div></div>),
      dataIndex: 'createdTime',
      width: '15%',
      render: (text: string) => {
        return (
          <BaseSpace>
            <BaseRow>
              <BaseCol>
                <span>Time:</span><br/>
                <span>{text}</span>
              </BaseCol>
            </BaseRow>
          </BaseSpace>
        );
      }
    },    
    {
      title: () => (
        <BaseRow justify="center">
          <BaseButton type="primary" size="small" onClick={() => handleCancelAllOrder()}>Cancel All</BaseButton>
        </BaseRow>
      ),
      dataIndex: 'cancel',
      render: (text: string, record: { clientOrderId: string, instrumentId: string}) => {
        return (
          <BaseRow justify="center">
            <BaseButton type="dashed" size="small" onClick={() => handleCancelOrder(record.instrumentId, record.clientOrderId)}>Cancel</BaseButton>
          </BaseRow>
        );
      },
    }
  ];
  return (
    <>
      <BaseTable
        columns={columns}
        dataSource={tableData}
        rowKey="orderId"
        showHeader={tableData.length > 0}
        pagination={false}
        scroll={{ x: 720 }}
      />
    </>
  );
};

export default OpenOrders;
