import React, { useEffect, useState } from 'react';
import { AssetDto, getAssetData, SymbolData, transferAllAsset, transferAsset, TransferAssetDto } from '@app/api/table.api';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useMounted } from '@app/hooks/useMounted';
import { SyncOutlined } from '@ant-design/icons';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { InputNumber } from 'antd';
import { BaseSwitch } from '@app/components/common/BaseSwitch/BaseSwitch';
const initialFormValues = {
  asset: undefined,
  amount: undefined,
  isAll: false
};
const Transfer: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [transferFrom, setFrom] = useState('FUNDING');
  const [transferTo, setTo] = useState('TRADING');

  const [assetData, setAssetData] = useState<AssetDto[]>([]);

  const { isMounted } = useMounted();
  const [form] = BaseForm.useForm();
  const [isFieldsChanged, setFieldsChanged] = useState(true);
  const [isTransferAll, setIsTransferAll] = useState(false);

  const [selectOptions, setSelectOptions] = useState<SymbolData[]>([]);

  const fetchData = (from: string) => {
    setLoading(true);
    getAssetData(from).then((res) => {
      if (isMounted.current) {
        setAssetData(res.assets);  
        setSelectOptions(res.assets.map(x => ({label: x.asset, value: x.asset})));
        form.setFieldsValue({ asset: undefined });
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData(transferFrom.toLowerCase());
  }, [isMounted, transferFrom]);

  const handleChangeTransfer = () => {    
    const temp = transferFrom;
    setFrom(transferTo);
    setTo(temp);
  }

  const handleTransfer = () => {
    setLoading(true);
    const asset = form.getFieldValue('asset');
    const amount = form.getFieldValue('amount');
    const isAll = form.getFieldValue('isAll');
    if(isAll)
    {
      transferAllAsset(transferFrom.toLocaleLowerCase() === 'funding' ? 1 : 2).then((rs)=>{
        if(!rs)
        {
          notificationController.error({ message: 'something went wrong!' });
        }
        else{
          fetchData(transferFrom.toLowerCase());
          notificationController.info({ message: 'Transfer is sucessfully' });
        }
      })
      .catch(()=>{
        notificationController.error({ message: 'Something went wrong!' });
      })
      .finally(()=>{
        setLoading(false);
      });
    }
    else{
      const formValues : AssetDto = {
        asset: asset,
        balance: amount,
        quantity: amount
      };
      const dto: TransferAssetDto = {
        asset: formValues,
        transferType: transferFrom.toLocaleLowerCase() === 'funding' ? 1 : 2
      }

      transferAsset(dto).then((rs) => {
        if(!rs)
        {
          notificationController.error({ message: 'Something went wrong!' });
        }
        else{
          fetchData(transferFrom.toLowerCase());
        }
      }).finally(()=>{
        setLoading(false);
      });
    }
  };
  const onSwitchChange = (checked: boolean) => {
    form.setFieldsValue({ isAll: checked });
    setIsTransferAll(checked);
  };

  const handleAssetChange = (value: unknown) => {
    form.setFieldsValue({ amount: 0 });
    
  };

  const onMaxClick = () => {
    const selectedAsset = form.getFieldValue('asset');
    if(selectedAsset)
    {
      var asset = assetData.find(x=>x.asset === selectedAsset);
      form.setFieldsValue({ amount: asset?.quantity ?? 0 });
    }
  };

  return (
    <>
      <BaseRow justify="space-between" align="middle">
        <BaseCol>
          <span>From</span><br/>
          <span style={{fontSize: 'bold'}}>{transferFrom}</span>
        </BaseCol>
        <BaseCol>
          <BaseButton type='dashed' icon={<SyncOutlined />} onClick={()=> handleChangeTransfer()}></BaseButton>
        </BaseCol>
        <BaseCol>
          <span>To</span><br/>
          <span style={{fontSize: 'bold'}}>{transferTo}</span>
        </BaseCol>
      </BaseRow>
      <BaseButtonsForm
          style={{marginTop: '20px'}}
          name="transferForm"
          form={form}
          isFieldsChanged={isFieldsChanged}
          initialValues={initialFormValues}
          footer={
            <>
              <BaseButtonsForm.Item style={{ marginTop: '30px' }}>
                <BaseButton disabled={loading} loading={loading} type="primary" htmlType="submit" style={{ float: 'right', height: '40px' }}>
                  Transfer
                </BaseButton>                
              </BaseButtonsForm.Item>
            </>

          }
          onFinish={handleTransfer}
        >
            <BaseRow gutter={{ xs: 10, md: 15, xl: 20 }}>
              <BaseCol xs={24} md={24}>
                <BaseButtonsForm.Item name='asset' label='Asset' style={{ marginBottom: '5px' }} rules={[{ required: !form.getFieldValue('isAll') && true, message: 'field is required' }]}>
                  <BaseSelect disabled={isTransferAll} placeholder='Select asset' options={selectOptions} onChange={handleAssetChange} />
                </BaseButtonsForm.Item>
              </BaseCol>
            </BaseRow>
            <BaseRow gutter={{ xs: 10, md: 15, xl: 20 }}>                            
              <BaseCol xs={24} md={24}>
                <BaseButtonsForm.Item name='amount' label='Amount' style={{ marginBottom: '5px' }} rules={[{ required: !form.getFieldValue('isAll') && true, message: 'value is required' }]}>
                  <InputNumber disabled={isTransferAll} min={0} style={{ width: '100%' }} addonAfter={<BaseButton disabled={isTransferAll} onClick={onMaxClick} style={{height: '30px'}} type='text'>Max</BaseButton>} />
                </BaseButtonsForm.Item>
              </BaseCol>
            </BaseRow>
            <BaseRow gutter={{ xs: 10, md: 15, xl: 20 }}>                            
              <BaseCol xs={24} md={24}>
                <BaseButtonsForm.Item name='isAll' label='Transfer all except USDT' valuePropName="checked" style={{ marginBottom: '5px' }}>
                  <BaseSwitch onChange={onSwitchChange} />
                </BaseButtonsForm.Item>
              </BaseCol>
            </BaseRow>
        </BaseButtonsForm>
    </>
  );
};

export default Transfer;
