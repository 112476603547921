import React from 'react';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import * as S from '../Header.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Balance } from '@app/components/Balance/Balance';
import { ProductOutlined, SlidersTwoTone } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';

interface MobileHeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
  openOrder: () => void;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({ toggleSider, isSiderOpened, openOrder }) => {
  return (
    <BaseRow justify="space-between" align="middle">
      <BaseCol>
        <BaseRow justify="start" align="middle" gutter={[20,20]}>
          <BaseCol>
            <ProfileDropdown /> 
          </BaseCol>
          <BaseCol>
            <Balance /> 
          </BaseCol>
          <BaseCol>
          </BaseCol>
        </BaseRow>               
      </BaseCol>
      
      <BaseCol>
        <BaseRow justify="start" align="middle" gutter={[20,20]}>
          <BaseCol>
            <BaseButton size='small' onClick={openOrder} icon={<SlidersTwoTone />} />
          </BaseCol>
          <S.BurgerCol>
            <S.MobileBurger onClick={toggleSider} isCross={isSiderOpened} />
          </S.BurgerCol>
        </BaseRow>
      </BaseCol>
      
    </BaseRow>
  );
};
