import React, { useEffect, useState } from 'react';
import { Button, Drawer, Spin, Tabs } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import OpenOrders from './OpenOrders';
import { getOrderPositionData, OpenOrderTableRow, OpenPositionTableRow } from '@app/api/table.api';
import { useMounted } from '@app/hooks/useMounted';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { CloseOutlined } from '@ant-design/icons';
import OpenPositions from './OpenPositions';
import PositionHistory from './PositionHistory';

interface OrderAndPositionProps {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}

const OrderAndPosition: React.FC<OrderAndPositionProps> = ({ isOpen, setOpen, ...props }) => {
  const onClose = () => {
    setOpen(false);
  };
  const [activeKey, setActiveKey] = useState('1');
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState<OpenOrderTableRow[]>([]);
  const [positionData, setPositionData] = useState<OpenPositionTableRow[]>([]);

  const { isMounted } = useMounted();
  const [orderText, setOrderText] = useState('Orders (0)');
  const [positionText, setPositionText] = useState('Positions (0)');

  
  const fetchData = () => {
    setLoading(true);
    getOrderPositionData().then((res) => {
      if (isMounted.current) {
        setOrderData(res.openOrders);    
        setPositionData(res.openPositions);    
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if(isOpen)
    {
      fetchData();
    }
  }, [isOpen]);

  useEffect(() => {
    setOrderText('Orders ('+ orderData.length + ')');
    setPositionText('Positions ('+ positionData.length + ')');
  }, [orderData, positionData]);

  const onChange = (key:string) => {
    setActiveKey(key);
  };

  const onSetOrderData = (orderData : OpenOrderTableRow[]) => {
    setOrderData(orderData);
  };

  const onSetPositionData = (data : OpenPositionTableRow[]) => {
    setPositionData(data);
  };

  return (
    <Drawer
        title="Order And Position"
        width="100%"
        closable={false}
        open={isOpen}
        extra={
          <BaseSpace>
            <Button type='dashed' icon={<CloseOutlined />} onClick={onClose}></Button>            
          </BaseSpace>
        }        
      >
        <Tabs activeKey={activeKey} onChange={onChange} destroyInactiveTabPane>
          <TabPane tab={orderText} key="1">
            {loading ? <Spin /> : <OpenOrders tableData={orderData} setTableData={onSetOrderData} />}
          </TabPane>
          <TabPane tab={positionText} key="2">
            {loading ? <Spin /> : <OpenPositions tableData={positionData} setTableData={onSetPositionData} />}
          </TabPane>
          <TabPane tab="Position History" key="3">
            <PositionHistory />
          </TabPane>
        </Tabs>
      </Drawer>
  );
};

export default OrderAndPosition;
