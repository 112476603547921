import React, { useEffect, useState } from 'react';
import { Header } from '../../../header/Header';
import MainSider from '../sider/MainSider/MainSider';
import MainContent from '../MainContent/MainContent';
import { MainHeader } from '../MainHeader/MainHeader';
import * as S from './MainLayout.styles';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { MEDICAL_DASHBOARD_PATH, DASHBOARD_PATH } from '@app/components/router/AppRouter';
import { useResponsive } from '@app/hooks/useResponsive';
import { Menu, MenuProps } from 'antd';
import { EyeOutlined, ScanOutlined, SpotifyOutlined, SwapOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { setMenuSelected } from '@app/store/slices/userSlice';
import OrderAndPosition from '../OrderAndPosition/OrderAndPosition';

const MainLayout: React.FC = () => {
  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(false);
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const [isOrderOpen, setOrderOpen] = useState(false);
  const { isDesktop } = useResponsive();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const menuSelected = useAppSelector((state) => state.user.menuSelected);

  const toggleSider = () => setSiderCollapsed(!siderCollapsed);
  const openOrder = () => setOrderOpen(true);


  useEffect(() => {
    setIsTwoColumnsLayout([MEDICAL_DASHBOARD_PATH, DASHBOARD_PATH].includes(location.pathname) && isDesktop);
  }, [location.pathname, isDesktop]);

  type MenuItem = Required<MenuProps>['items'][number];

  const items: MenuItem[] = [
    {
      label: 'Swap',
      key: '2',
      icon: <SwapOutlined />,
      style: { width: '25%' }
    },
    {
      label: 'Margin',
      key: '1',
      icon: <EyeOutlined />,
      style: { width: '25%' }
    },
    {
      label: 'Spot',
      key: '0',
      icon: <SpotifyOutlined />,
      style: { width: '25%' }
    },
    {
      label: 'Scanner',
      key: '3',
      icon: <ScanOutlined />,
      style: { width: '25%' }
    }  
  ];
  const onMenuClick: MenuProps['onClick'] = (e) => {
    dispatch(setMenuSelected(e.key));
    if (e.key === '3') {
      navigate('/scanners');
    }
    if (e.key === '2') {
      navigate('/configurations?tab=swap');
    }
    if (e.key === '1') {
      navigate('/configurations?tab=margin');
    }
    if (e.key === '0') {
      navigate('/configurations?tab=spot');
    }
  };
  return (
    <S.LayoutMaster>
      <MainSider isCollapsed={siderCollapsed} setCollapsed={setSiderCollapsed} />
      {isOrderOpen && (<OrderAndPosition isOpen={isOrderOpen} setOpen={setOrderOpen} />)}
      <S.LayoutMain>
        <MainHeader isTwoColumnsLayout={isTwoColumnsLayout}>
          <Header toggleSider={toggleSider} isSiderOpened={!siderCollapsed} isTwoColumnsLayout={isTwoColumnsLayout} openOrder={openOrder} />
        </MainHeader>
        <MainContent id="main-content" $isTwoColumnsLayout={isTwoColumnsLayout}>
          <div style={{paddingBottom: "50px"}}>
            <Outlet />
          </div>
        </MainContent>
        <S.MenuMain onClick={onMenuClick} selectedKeys={[menuSelected]} mode="horizontal" items={items} style={{ position: 'fixed', bottom: 0, height: '70px', width: '100%', borderTop: "1px solid gray" }} className="vertical-menu" />
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};

export default MainLayout;
