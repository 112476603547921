import React from 'react';
import * as S from './MainSider/MainSider.styles';
import logo from 'assets/logo.png';
import logoDark from 'assets/logo-dark.png';
import { useAppSelector } from '@app/hooks/reduxHooks';

interface SiderLogoProps {
  isSiderCollapsed: boolean;
  toggleSider: () => void;
}
export const SiderLogo: React.FC<SiderLogoProps> = ({ isSiderCollapsed, toggleSider }) => {
  const theme = useAppSelector((state) => state.theme.theme);

  const img = theme === 'dark' ? logoDark : logo;

  return (
    <S.SiderLogoDiv>
      <S.SiderLogoLink to="/">
        <img src={img} alt="OKX V1" width={48} height={48} />
        <S.BrandSpan>OKX V1</S.BrandSpan>
      </S.SiderLogoLink>      
    </S.SiderLogoDiv>
  );
};
