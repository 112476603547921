import React from 'react';
import {
  SettingOutlined,
  ApiOutlined,
  OrderedListOutlined,
  UserOutlined
} from '@ant-design/icons';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  role?: number[]
}

export const sidebarNavigation: SidebarNavigationItem[] = [  
  {
    title: 'Api Settings',
    key: 'api-settings',
    icon: <ApiOutlined />,
    url: '/api-settings'
  },
  {
    title: 'General Settings',
    key: 'general-settings',
    icon: <SettingOutlined />,
    url: '/general-settings'
  }  ,
  {
    title: 'Assets',
    key: 'assets',
    icon: <OrderedListOutlined />,
    url: '/assets'
  },
  {
    title: 'Traders',
    key: 'traders',
    icon: <UserOutlined />,
    url: '/traders',
    role: [0]
  }
]