import React, { useEffect, useState } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { AllUserReportDto, getAllPnLData, UserReportDto } from '@app/api/table.api';
import { ColumnsType } from 'antd/lib/table';
import { useMounted } from '@app/hooks/useMounted';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { currencyFormat } from '@app/utils/utils';
import { Divider, Statistic, Tag } from 'antd';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

const PnLReport: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [allPnLData, setAllPnLData] = useState<AllUserReportDto>({});

  const { isMounted } = useMounted();

  const fetchData = () => {
    setLoading(true);
    getAllPnLData().then((res) => {
      if (isMounted.current) {
        setAllPnLData(res);  
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, [isMounted]); 
  
  
  const columns: ColumnsType<UserReportDto> = [
    {
      title: () => (<div>Name</div>),
      dataIndex: 'fullName',
    },
    {
      title: () => (<div>Balance</div>),
      dataIndex: 'balance',
      width: '150px',
      render: (text: string, record: {balance?: number}) => {
        return (
          <span>{currencyFormat(record.balance ?? 0)}</span>
        );
      }
    },
    {
      title: () => (<div>Budget</div>),
      dataIndex: 'budget',
      width: '150px',
      render: (text: string, record: {budget?: number}) => {
        return (
          <span>{currencyFormat(record.budget ?? 0)}</span>
        );
      }
    },
    {
      title: () => (<div>PnL</div>),
      dataIndex: 'pnL',
      width: '150px',
      render: (text: string, record: {pnL?: number}) => {
        return (
          <Tag color={(record.pnL ?? 0) >= 0 ?'green':'red'} >{currencyFormat(record.pnL ?? 0)}</Tag>
        );
      }
    },
    {
      title: () => (<div>ROI</div>),
      dataIndex: 'roi',
      width: '150px',
      render: (text: string, record: {roi?: number}) => {
        return (
          <Tag color={(record.roi ?? 0) >= 0 ?'green':'red'} >{(record.roi ?? 0).toFixed(1)}%</Tag>
        );
      }
    }
  ];
  return (
    <>
      
      <BaseTable
        loading={loading}
        columns={columns}
        dataSource={allPnLData.userReports}
        rowKey="fullName"
        showHeader={true}
        pagination={false}
      />

      <Divider />

      <BaseRow gutter={16} justify="center">
        <BaseCol span={6}>
          <Statistic title={<span style={{fontSize: "14px", display: "table", margin: "0 auto"}}>Total Budget</span>} valueStyle={{fontSize: "16px", textAlign: "center"}} value={allPnLData.totalBudget} precision={1} prefix="$" />
        </BaseCol>
        <BaseCol span={6}>
          <Statistic title={<span style={{fontSize: "14px", display: "table", margin: "0 auto"}}>Total Balance</span>} valueStyle={{fontSize: "16px", textAlign: "center"}} value={allPnLData.totalBalance} precision={1} prefix="$" />
        </BaseCol>
        <BaseCol span={6}>
          <Statistic title={<span style={{fontSize: "14px", display: "table", margin: "0 auto"}}>Total Balance</span>} valueStyle={{fontSize: "16px", textAlign: "center"}} value={allPnLData.totalPnL} precision={1} prefix="$" />
        </BaseCol>
        <BaseCol span={6}>
          <Statistic title={<span style={{fontSize: "14px", display: "table", margin: "0 auto"}}>Total ROI</span>} valueStyle={{fontSize: "16px", textAlign: "center"}} value={allPnLData.totalROI} precision={1} prefix={(allPnLData.totalROI ?? 0) >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />} suffix="%" />
        </BaseCol>
      </BaseRow>
    </>
  );
};

export default PnLReport;
