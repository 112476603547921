import React from 'react';
import { MobileHeader } from './layouts/MobileHeader';

interface HeaderProps {
  toggleSider: () => void;
  openOrder: () => void;
  isSiderOpened: boolean;
  isTwoColumnsLayout: boolean;
}

export const Header: React.FC<HeaderProps> = ({ toggleSider, isSiderOpened, isTwoColumnsLayout, openOrder }) => {
  return (
    <MobileHeader toggleSider={toggleSider} isSiderOpened={isSiderOpened} openOrder={openOrder} />
  );
};
